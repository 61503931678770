// src/components/Footer.tsx
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    const footerLinks = [
        { name: 'Home', path: '/' },
        { name: 'Services', path: '/#services' },
        { name: 'Work', path: '/#work' },
        { name: 'Portfolio', path: '/portfolio' },
        { name: 'Contact', path: '/#contact' },
        { name: 'Terms', path: '/terms' },
        { name: 'Privacy', path: '/privacy' }
    ];

    return (
        <footer className="bg-black pt-16 pb-8 relative overflow-hidden">
            {/* Enhanced background effects */}
            <div className="absolute inset-0 pointer-events-none">
                <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-blue-500/20 to-transparent" />
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_120%,_rgba(56,182,255,0.03)_0%,_transparent_60%)]" />
            </div>

            <div className="max-w-7xl mx-auto px-4 relative z-10">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                    {/* Logo and description */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        className="col-span-1"
                    >
                        <div className="mb-6">
                            <img
                                src="/assets/neuralwayLogo.svg"
                                alt="Neuralway"
                                className="h-10 w-auto"
                            />
                        </div>
                        <p className="text-white/60 text-sm max-w-xs mb-4">
                            Innovative AI solutions transforming businesses through cutting-edge technology and creative problem-solving.
                        </p>

                        {/* Added social links as a bonus */}
                        <div className="flex space-x-4 mt-6">
                            {['Twitter', 'LinkedIn', 'GitHub'].map((social, i) => (
                                <motion.a
                                    key={social}
                                    href="#" // Replace with actual social media links
                                    className="p-2 rounded-full bg-white/5 hover:bg-white/10 border border-white/10 transition-colors"
                                    initial={{ opacity: 0, y: 10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{
                                        duration: 0.3,
                                        delay: 0.3 + i * 0.1
                                    }}
                                    whileHover={{ y: -3 }}
                                >
                                    <svg className="w-4 h-4 text-white/70" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d={
                                            social === 'Twitter'
                                                ? 'M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'
                                                : social === 'LinkedIn'
                                                    ? 'M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z M4 6a2 2 0 100-4 2 2 0 000 4z'
                                                    : 'M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 00-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0020 4.77 5.07 5.07 0 0019.91 1S18.73.65 16 2.48a13.38 13.38 0 00-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 005 4.77a5.44 5.44 0 00-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 009 18.13V22'
                                        } />
                                    </svg>
                                </motion.a>
                            ))}
                        </div>
                    </motion.div>

                    {/* Quick links */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="col-span-1"
                    >
                        <h3 className="text-white font-semibold mb-6">Quick Links</h3>
                        <ul className="space-y-3">
                            {footerLinks.slice(0, 4).map((link, index) => (
                                <motion.li
                                    key={link.name}
                                    initial={{ opacity: 0, x: -10 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.3, delay: 0.4 + (index * 0.1) }}
                                >
                                    <Link
                                        to={link.path}
                                        className="text-white/60 hover:text-white transition-colors relative inline-block group"
                                    >
                                        {link.name}
                                        <span className="absolute bottom-0 left-0 w-0 h-px bg-white/40 transition-all duration-300 group-hover:w-full"></span>
                                    </Link>
                                </motion.li>
                            ))}
                        </ul>
                    </motion.div>

                    {/* Contact information */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                        className="col-span-1"
                    >
                        <h3 className="text-white font-semibold mb-6">Get In Touch</h3>
                        <ul className="space-y-3">
                            <motion.li
                                initial={{ opacity: 0, x: -10 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.3, delay: 0.7 }}
                                className="flex items-start space-x-3"
                            >
                                <svg className="w-5 h-5 text-blue-400 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                                <a href="mailto:zero@neuralway.ai" className="text-white/60 hover:text-white transition-colors">
                                    zero@neuralway.ai
                                </a>
                            </motion.li>
                            <motion.li
                                initial={{ opacity: 0, x: -10 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.3, delay: 0.8 }}
                                className="flex items-start space-x-3"
                            >
                                <svg className="w-5 h-5 text-green-400 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                </svg>
                                <a href="https://wa.me/918197218658" className="text-white/60 hover:text-white transition-colors">
                                    +91 8197218658
                                </a>
                            </motion.li>
                            <motion.li
                                initial={{ opacity: 0, x: -10 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.3, delay: 0.9 }}
                                className="flex items-start space-x-3"
                            >
                                <svg className="w-5 h-5 text-purple-400 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <span className="text-white/60">
                                    Global / Remote
                                </span>
                            </motion.li>
                        </ul>
                    </motion.div>
                </div>

                {/* Divider */}
                <div className="h-px bg-white/10 my-8"></div>

                {/* Bottom section with copyright and legal links */}
                <div className="flex flex-col md:flex-row justify-between items-center text-white/40 text-sm">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.9 }}
                    >
                        © {currentYear} Neuralway Technologies. All rights reserved.
                    </motion.div>

                    <motion.div
                        className="flex space-x-6 mt-4 md:mt-0"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 1 }}
                    >
                        {footerLinks.slice(5).map((link) => (
                            <Link
                                key={link.name}
                                to={link.path}
                                className="hover:text-white/70 transition-colors"
                            >
                                {link.name}
                            </Link>
                        ))}
                    </motion.div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;