import { motion, useScroll, useMotionValueEvent, useTransform } from 'framer-motion';
import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
    const { scrollY } = useScroll();
    const [expanded, setExpanded] = useState(false);
    const [showLogo, setShowLogo] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const location = useLocation();
    const navigate = useNavigate();
    const isHome = location.pathname === '/';

    // Enhanced scroll tracking for parallax effect
    const navOpacity = useTransform(scrollY, [0, 300], [0.5, 1]);
    const navBlur = useTransform(scrollY, [0, 300], [0, 8]);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };
        checkMobile();
        window.addEventListener('resize', checkMobile);

        // Close menu when clicking outside
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('resize', checkMobile);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Enhanced threshold with smoother transition
    const EXPAND_THRESHOLD = 350;

    useMotionValueEvent(scrollY, 'change', (latest: number) => {
        if (latest > EXPAND_THRESHOLD) {
            setExpanded(true);
            // Only show logo on desktop when expanded
            setShowLogo(!isMobile);
        } else {
            setShowLogo(false);
            setExpanded(false);
        }
    });

    const handleNavigation = async (sectionId: string) => {
        setMenuOpen(false);
        if (isHome) {
            requestAnimationFrame(() => {
                const element = document.getElementById(sectionId);
                if (element) {
                    const offset = isMobile ? 60 : 80;
                    const elementPosition = element.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - offset;
                    window.scrollTo({ top: sectionId === 'hero' ? 0 : offsetPosition, behavior: 'smooth' });
                }
            });
        } else {
            await navigate('/');
            // Add slight delay to ensure page loads before scrolling
            setTimeout(() => {
                const element = document.getElementById(sectionId);
                if (element) {
                    const offset = isMobile ? 60 : 80;
                    const elementPosition = element.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - offset;
                    window.scrollTo({ top: sectionId === 'hero' ? 0 : offsetPosition, behavior: 'smooth' });
                }
            }, 100);
        }
    };

    const navItems = [
        { label: 'Home', id: 'hero' },
        { label: 'Services', id: 'services' },
        { label: 'Work', id: 'work' },
        { label: 'Contact', id: 'contact' }
    ];

    // Enhanced container animations
    const containerVariants = {
        initial: {
            width: isMobile ? '200px' : '400px',
            borderRadius: '9999px',
            backgroundColor: 'rgba(0,0,0,0.3)',
            backdropFilter: 'blur(8px)',
            transition: {
                duration: 0.6,
                ease: [0.25, 0.1, 0.25, 1]
            }
        },
        expanded: {
            width: isMobile ? '90%' : '95%',
            borderRadius: '1.25rem',
            backgroundColor: 'rgba(0,0,0,0.65)',
            backdropFilter: 'blur(12px)',
            transition: {
                duration: 0.6,
                ease: [0.25, 0.1, 0.25, 1]
            }
        }
    };

    // Enhanced logo animations
    const logoVariants = {
        hidden: {
            opacity: 0,
            scale: 0.9,
            y: 10,
            transition: {
                duration: 0.5,
                ease: [0.4, 0.0, 0.2, 1]
            }
        },
        visible: {
            opacity: 1,
            scale: 1,
            y: 0,
            transition: {
                duration: 0.5,
                ease: [0.4, 0.0, 0.2, 1]
            }
        }
    };

    const renderDesktopContent = () => {
        if (isMobile) return null;

        return (
            <div className="flex items-center w-full">
                {!expanded ? (
                    <div className="flex items-center justify-center w-full gap-6">
                        {navItems.map((item, idx) => (
                            <motion.button
                                key={item.id}
                                onClick={() => handleNavigation(item.id)}
                                className="text-white/90 hover:text-white font-medium text-base relative group"
                                initial={{ opacity: 0.6, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0.6, scale: 0.9 }}
                                transition={{ duration: 0.5, ease: [0.4, 0.0, 0.2, 1], delay: idx * 0.07 }}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                {item.label}
                                <motion.div
                                    className="absolute -bottom-1 left-0 h-[2px] bg-gradient-to-r from-blue-500 to-purple-500"
                                    initial={{ width: 0 }}
                                    whileHover={{ width: '100%' }}
                                    transition={{ duration: 0.2 }}
                                />
                            </motion.button>
                        ))}
                    </div>
                ) : (
                    <div className="flex items-center justify-between w-full">
                        <motion.button
                            onClick={() => handleNavigation('hero')}
                            className="px-4 py-2 text-white/90 hover:text-white font-medium text-base relative group"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -20 }}
                            transition={{ duration: 0.5, ease: [0.4, 0.0, 0.2, 1] }}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            Home
                            <motion.div
                                className="absolute -bottom-1 left-0 h-[2px] bg-gradient-to-r from-blue-500 to-purple-500"
                                initial={{ width: 0 }}
                                whileHover={{ width: '100%' }}
                                transition={{ duration: 0.2 }}
                            />
                        </motion.button>
                        <div className="flex items-center gap-8 mr-6">
                            {navItems.slice(1).map((item, idx) => (
                                <motion.button
                                    key={item.id}
                                    onClick={() => handleNavigation(item.id)}
                                    className="text-white/90 hover:text-white font-medium text-base relative group"
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: 20 }}
                                    transition={{
                                        duration: 0.5,
                                        ease: [0.4, 0.0, 0.2, 1],
                                        delay: idx * 0.07
                                    }}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    {item.label}
                                    <motion.div
                                        className="absolute -bottom-1 left-0 h-[2px] bg-gradient-to-r from-blue-500 to-purple-500"
                                        initial={{ width: 0 }}
                                        whileHover={{ width: '100%' }}
                                        transition={{ duration: 0.2 }}
                                    />
                                </motion.button>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderMobileContent = () => {
        if (!isMobile) return null;

        return (
            <>
                <div className="flex items-center justify-between w-full px-3">
                    {/* Always show Home button in mobile mode, never show the logo */}
                    <button
                        onClick={() => handleNavigation('hero')}
                        className="p-2 text-white hover:text-white/90 transition-colors"
                    >
                        <svg
                            className="w-5 h-5"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                            <polyline points="9 22 9 12 15 12 15 22" />
                        </svg>
                    </button>

                    <button
                        onClick={() => setMenuOpen(!menuOpen)}
                        className="p-2 text-white hover:text-white/90 transition-colors"
                    >
                        <motion.svg
                            key={menuOpen ? 'x' : 'menu'}
                            initial={{ rotate: 0, opacity: 1 }}
                            animate={{
                                rotate: menuOpen ? 90 : 0,
                                opacity: 1
                            }}
                            transition={{
                                duration: 0.3,
                                type: 'spring',
                                stiffness: 300,
                                damping: 20
                            }}
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                        >
                            {menuOpen ? (
                                <>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </>
                            ) : (
                                <>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                                </>
                            )}
                        </motion.svg>
                    </button>
                </div>

                {menuOpen && (
                    <motion.div
                        ref={menuRef}
                        initial={{ opacity: 0, y: -20, height: 0 }}
                        animate={{ opacity: 1, y: 0, height: 'auto' }}
                        exit={{ opacity: 0, y: -20, height: 0 }}
                        transition={{
                            duration: 0.5,
                            ease: [0.4, 0.0, 0.2, 1]
                        }}
                        className="absolute top-full left-0 right-0 mt-2 bg-black/80 backdrop-blur-xl border border-white/10 rounded-xl overflow-hidden shadow-lg z-50"
                    >
                        <div className="py-2 flex flex-col">
                            {navItems.map((item, index) => (
                                <motion.button
                                    key={item.id}
                                    onClick={() => handleNavigation(item.id)}
                                    initial={{ opacity: 0, x: -10 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: -10 }}
                                    transition={{
                                        duration: 0.5,
                                        delay: index * 0.1,
                                        ease: [0.4, 0.0, 0.2, 1]
                                    }}
                                    className="w-full px-6 py-4 text-left text-white hover:bg-white/10 transition-colors"
                                >
                                    {item.label}
                                </motion.button>
                            ))}
                        </div>
                    </motion.div>
                )}
            </>
        );
    };

    return (
        <div className="fixed top-0 left-0 w-full flex justify-center z-50 pt-4 md:pt-6 pointer-events-none">
            <motion.nav
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.7, ease: [0.25, 0.1, 0.25, 1], delay: 0.2 }}
                className="pointer-events-auto flex justify-center w-full px-3 md:px-4"
                style={{ opacity: navOpacity }}
            >
                <motion.div
                    variants={containerVariants}
                    animate={expanded ? 'expanded' : 'initial'}
                    className="relative border border-white/10 shadow-lg flex items-center"
                    style={{
                        height: isMobile ? '54px' : '68px',
                        boxShadow: '0 10px 30px -5px rgba(0, 0, 0, 0.3)',
                        backdropFilter: `blur(${navBlur.get()}px)`
                    }}
                >
                    {/* Only show logo when expanded on desktop */}
                    {showLogo && !isMobile && (
                        <motion.div
                            variants={logoVariants}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            className="absolute inset-0 flex items-center justify-center pointer-events-none"
                        >
                            <img
                                src="/assets/neuralwayLogo.svg"
                                alt="Neuralway"
                                className="h-8 md:h-10 w-auto"
                            />
                        </motion.div>
                    )}

                    <div className="flex items-center justify-center w-full px-4 md:px-6 relative">
                        {renderDesktopContent()}
                        {renderMobileContent()}
                    </div>
                </motion.div>
            </motion.nav>
        </div>
    );
};

export default Navbar;