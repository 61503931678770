// src/components/Contact.tsx
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useState, useEffect } from 'react';

const Contact = () => {
    const controls = useAnimation();
    const [emailHovered, setEmailHovered] = useState(false);
    const [whatsappHovered, setWhatsappHovered] = useState(false);

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.2
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    // Create particles for dynamic background
    const particles = Array.from({ length: 20 }, (_, i) => ({
        id: i,
        size: Math.random() * 4 + 1,
        x: Math.random() * 100,
        y: Math.random() * 100,
        duration: Math.random() * 20 + 10
    }));

    return (
        <section ref={ref} id="contact" className="py-32 relative overflow-hidden">
            {/* Enhanced Background Effects */}
            <div className="absolute inset-0">
                <div
                    className="absolute inset-0"
                    style={{
                        background: 'radial-gradient(circle at 50% 50%, rgba(56,182,255,0.07) 0%, transparent 70%)',
                    }}
                />

                {/* Dynamic floating particles */}
                {particles.map((particle) => (
                    <motion.div
                        key={particle.id}
                        className="absolute rounded-full bg-blue-500/20"
                        style={{
                            width: `${particle.size}px`,
                            height: `${particle.size}px`,
                            left: `${particle.x}%`,
                            top: `${particle.y}%`,
                            filter: 'blur(1px)'
                        }}
                        animate={{
                            y: [0, -20, 0],
                            opacity: [0.3, 0.7, 0.3]
                        }}
                        transition={{
                            duration: particle.duration,
                            repeat: Infinity,
                            ease: "easeInOut"
                        }}
                    />
                ))}

                {/* Gradient lines */}
                <div className="absolute left-0 right-0 top-0 h-px bg-gradient-to-r from-transparent via-blue-500/20 to-transparent" />
                <div className="absolute left-0 right-0 bottom-0 h-px bg-gradient-to-r from-transparent via-purple-500/20 to-transparent" />
            </div>

            <div className="max-w-4xl mx-auto px-4 relative z-10">
                <motion.div
                    className="text-center space-y-16"
                    initial="hidden"
                    animate={controls}
                    variants={{
                        hidden: { opacity: 0 },
                        visible: {
                            opacity: 1,
                            transition: {
                                staggerChildren: 0.2,
                                delayChildren: 0.3
                            }
                        }
                    }}
                >
                    {/* Main Title & Subtitle with enhanced animation */}
                    <motion.div
                        className="space-y-5"
                        variants={{
                            hidden: { opacity: 0, y: 30 },
                            visible: {
                                opacity: 1,
                                y: 0,
                                transition: {
                                    duration: 0.8,
                                    ease: [0.25, 0.1, 0.25, 1]
                                }
                            }
                        }}
                    >
                        <span className="text-blue-400 text-sm font-semibold tracking-wider uppercase inline-block bg-blue-500/10 px-4 py-1 rounded-full">
                            Get In Touch
                        </span>
                        <h2 className="text-5xl md:text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white via-blue-100 to-white">Connect</h2>
                        <p className="text-xl text-white/70 font-light">Let's build something extraordinary.</p>
                    </motion.div>

                    {/* Email Section with enhanced animations */}
                    <motion.div
                        variants={{
                            hidden: { opacity: 0, scale: 0.9 },
                            visible: {
                                opacity: 1,
                                scale: 1,
                                transition: {
                                    duration: 0.6,
                                    type: "spring",
                                    stiffness: 100
                                }
                            }
                        }}
                        className="relative"
                    >
                        {/* Email glow effect */}
                        <motion.div
                            className="absolute inset-0 rounded-full bg-blue-500/10 blur-xl"
                            animate={{
                                scale: emailHovered ? 1.2 : 1,
                                opacity: emailHovered ? 0.8 : 0.2
                            }}
                            transition={{ duration: 0.5 }}
                        />

                        <motion.a
                            href="mailto:zero@neuralway.ai"
                            className="inline-block group relative"
                            onMouseEnter={() => setEmailHovered(true)}
                            onMouseLeave={() => setEmailHovered(false)}
                            whileHover={{ scale: 1.02 }}
                        >
                            <div className="text-3xl md:text-4xl text-white font-medium tracking-tight relative">
                                zero@neuralway.ai
                                <motion.div
                                    className="h-px bg-gradient-to-r from-blue-500 to-purple-500 absolute bottom-0 left-0"
                                    initial={{ width: 0 }}
                                    animate={{ width: emailHovered ? '100%' : '0%' }}
                                    transition={{ duration: 0.3 }}
                                />
                            </div>

                            {/* Email icon with animation */}
                            <motion.div
                                className="absolute -right-12 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity"
                                animate={{
                                    x: emailHovered ? 0 : 10,
                                    opacity: emailHovered ? 1 : 0
                                }}
                                transition={{ duration: 0.3 }}
                            >
                                <svg className="w-6 h-6 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                            </motion.div>
                        </motion.a>
                    </motion.div>

                    {/* WhatsApp Button with enhanced design */}
                    <motion.div
                        variants={{
                            hidden: { opacity: 0, y: 20 },
                            visible: {
                                opacity: 1,
                                y: 0,
                                transition: {
                                    duration: 0.5
                                }
                            }
                        }}
                        className="pt-8 relative"
                    >
                        {/* Button glow */}
                        <motion.div
                            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-32 h-32 rounded-full bg-green-500/10 blur-xl"
                            animate={{
                                scale: whatsappHovered ? 1.2 : 1,
                                opacity: whatsappHovered ? 0.3 : 0.15
                            }}
                            transition={{ duration: 0.5 }}
                        />

                        <motion.a
                            href="https://wa.me/918197218658"
                            className="inline-flex items-center gap-3 px-8 py-4 rounded-xl bg-white/5 backdrop-blur-sm border border-green-500/30 transition-all duration-300 relative overflow-hidden"
                            whileHover={{ scale: 1.02, borderColor: 'rgba(34, 197, 94, 0.5)' }}
                            whileTap={{ scale: 0.98 }}
                            onMouseEnter={() => setWhatsappHovered(true)}
                            onMouseLeave={() => setWhatsappHovered(false)}
                        >
                            {/* Gradient background that appears on hover */}
                            <motion.div
                                className="absolute inset-0 bg-gradient-to-r from-green-500/10 to-emerald-500/10 opacity-0"
                                animate={{ opacity: whatsappHovered ? 1 : 0 }}
                                transition={{ duration: 0.3 }}
                            />

                            {/* Shimmer effect */}
                            <motion.div
                                className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent -translate-x-full"
                                animate={{
                                    x: whatsappHovered ? '200%' : '-100%'
                                }}
                                transition={{
                                    duration: 1,
                                    ease: "easeInOut",
                                }}
                            />

                            {/* Enhanced WhatsApp Icon */}
                            <motion.div
                                className="relative z-10 rounded-full p-2 bg-green-500/20"
                                animate={{ rotate: whatsappHovered ? [0, -10, 10, -10, 10, 0] : 0 }}
                                transition={{
                                    duration: 0.6,
                                    ease: "easeInOut",
                                    delay: 0.1
                                }}
                            >
                                <svg
                                    className="w-5 h-5 text-green-400 group-hover:text-green-300 transition-colors"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                >
                                    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004A9.87 9.87 0 017.016 19.8l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z"/>
                                </svg>
                            </motion.div>

                            {/* Text with enhanced styling */}
                            <span className="relative z-10 text-white/90 font-medium transition-colors">
                                Message on WhatsApp
                            </span>
                        </motion.a>
                    </motion.div>


                </motion.div>
            </div>
        </section>
    );
};

export default Contact;