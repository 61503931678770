import { motion } from 'framer-motion';
import { useCallback, useState, useEffect } from 'react';
import Particles from 'react-particles';
import { loadSlim } from "tsparticles-slim";
import { Engine } from "tsparticles-engine";

const Hero = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    // Check for mobile device
    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const particlesInit = useCallback(async (engine: Engine) => {
        await loadSlim(engine);
    }, []);

    const scrollToServices = () => {
        const element = document.getElementById('services');
        if (element) {
            const offset = 80;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div id="hero" className="relative min-h-screen flex items-center justify-center bg-black overflow-hidden">
            {/* Enhanced particle background */}
            <Particles
                id="tsparticles"
                init={particlesInit}
                options={{
                    fullScreen: { enable: false },
                    background: { color: { value: "#000000" } },
                    fpsLimit: 60,
                    particles: {
                        color: { value: ["#3b82f6", "#8b5cf6", "#60a5fa"] },
                        links: {
                            color: "#4080ff",
                            distance: 150,
                            enable: true,
                            opacity: 0.3,
                            width: 1,
                        },
                        move: {
                            enable: true,
                            speed: 0.5,
                            direction: "none",
                            random: true,
                            straight: false,
                            outModes: "out",
                            attract: {
                                enable: true,
                                rotateX: 600,
                                rotateY: 1200
                            }
                        },
                        number: {
                            density: { enable: true, area: 800 },
                            value: isMobile ? 40 : 80,
                        },
                        opacity: {
                            value: { min: 0.1, max: 0.25 },
                            animation: {
                                enable: true,
                                speed: 0.5,
                                minimumValue: 0.1,
                            },
                        },
                        shape: { type: "circle" },
                        size: {
                            value: { min: 1, max: 3 },
                            animation: {
                                enable: true,
                                speed: 0.8,
                                minimumValue: 0.5,
                            },
                        },
                        interactivity: {
                            events: {
                                onHover: {
                                    enable: true,
                                    mode: "grab",
                                    parallax: {
                                        enable: true,
                                        force: 50,
                                        smooth: 20
                                    }
                                }
                            },
                            modes: {
                                grab: {
                                    distance: 120,
                                    links: {
                                        opacity: 0.5,
                                        color: "#8b5cf6"
                                    }
                                }
                            }
                        }
                    },
                }}
                className="absolute inset-0"
            />

            {/* Subtle gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-b from-blue-900/5 via-transparent to-transparent" />

            <div className="relative z-10 text-center px-4 max-w-screen-xl mx-auto">
                <motion.div
                    className="mb-16"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <img
                        src="/assets/neuralwayLogo.svg"
                        alt="Neuralway"
                        className="h-16 md:h-24 mx-auto"
                    />
                </motion.div>

                <motion.div
                    className="space-y-8 mb-16"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                >
                    <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold">
                        <motion.span
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            className="block"
                        >
                            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
                                Redefining
                            </span>
                        </motion.span>
                        <motion.span
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.6 }}
                            className="block mt-2 text-white"
                        >
                            Possibilities
                        </motion.span>
                    </h1>
                    <motion.p
                        className="text-xl md:text-2xl text-white/80 max-w-2xl mx-auto font-light"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.8, delay: 0.9 }}
                    >
                        Unlock significant potential from cutting-edge AI technology.
                    </motion.p>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 1.2 }}
                >
                    <motion.button
                        onClick={scrollToServices}
                        className="relative px-8 py-3 rounded-lg font-medium overflow-hidden group"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        {/* Button background */}
                        <motion.div
                            className="absolute inset-0 bg-gradient-to-r from-blue-600 to-purple-600"
                            initial={{ opacity: 0.8 }}
                            animate={{ opacity: isHovered ? 1 : 0.8 }}
                            transition={{ duration: 0.3 }}
                        />

                        {/* Animated glow/shadow */}
                        <motion.div
                            className="absolute inset-0 rounded-lg"
                            initial={{ boxShadow: "0 0 0 0 rgba(59, 130, 246, 0)" }}
                            animate={{
                                boxShadow: isHovered
                                    ? "0 0 20px 5px rgba(59, 130, 246, 0.5)"
                                    : "0 0 0 0 rgba(59, 130, 246, 0)"
                            }}
                            transition={{ duration: 0.3 }}
                        />

                        {/* Button text */}
                        <span className="relative z-10 text-white flex items-center">
                            Get Started
                            <svg className="ml-2 w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                            </svg>
                        </span>
                    </motion.button>
                </motion.div>

                {/* Simplified scroll indicator - only text, no visual element */}
                <motion.div
                    className="mt-16 text-white/50 text-sm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.7 }}
                    transition={{ delay: 1.5, duration: 0.8 }}
                >
                    Scroll to explore
                </motion.div>
            </div>
        </div>
    );
};

export default Hero;