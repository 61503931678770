// src/components/LeadGen.tsx
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useState } from 'react';

interface Feature {
    title: string;
    description: string;
    icon: JSX.Element;
}

const features: Feature[] = [
    {
        title: 'AI-Powered Prospecting',
        description: 'Identify and qualify high-value leads using advanced machine learning algorithms',
        icon: (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 1.5, repeat: Infinity, repeatType: 'loop', repeatDelay: 0.5 }}
                />
            </svg>
        )
    },
    {
        title: 'Multi-Channel Outreach',
        description: 'Orchestrate personalized campaigns across email, LinkedIn, and other platforms',
        icon: (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 1.5, repeat: Infinity, repeatType: 'loop', repeatDelay: 0.5 }}
                />
            </svg>
        )
    },
    {
        title: 'Behavioral Analytics',
        description: 'Track and analyze prospect engagement to optimize conversion strategies',
        icon: (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 1.5, repeat: Infinity, repeatType: 'loop', repeatDelay: 0.5 }}
                />
            </svg>
        )
    },
    {
        title: 'Smart Follow-ups',
        description: 'Automated, contextual follow-ups that maintain genuine conversation threads',
        icon: (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 1.5, repeat: Infinity, repeatType: 'loop', repeatDelay: 0.5 }}
                />
            </svg>
        )
    }
];

const LeadGen = () => {
    const [isHovered, setIsHovered] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    const capabilities = [
        { title: 'Personalized Outreach', description: 'Tailored communication for each prospect' },
        { title: 'Smart Automation', description: 'Streamlined workflows without losing the human touch' },
        { title: 'Data-Driven Insights', description: 'Make informed decisions based on real engagement data' }
    ];

    return (
        <section ref={ref} className="py-32 relative overflow-hidden">
            {/* Enhanced Background Effects */}
            <div className="absolute inset-0 pointer-events-none">
                <div className="absolute inset-0 bg-gradient-to-b from-black via-black/95 to-black" />

                {/* Dynamic animated orbs/particles */}
                <motion.div
                    className="absolute top-1/4 left-1/4 w-64 h-64 rounded-full bg-blue-500/10 blur-3xl"
                    animate={{
                        x: [0, 20, 0],
                        y: [0, 15, 0]
                    }}
                    transition={{
                        duration: 20,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatType: "mirror"
                    }}
                />

                <motion.div
                    className="absolute bottom-1/3 right-1/4 w-80 h-80 rounded-full bg-purple-500/10 blur-3xl"
                    animate={{
                        x: [0, -20, 0],
                        y: [0, -15, 0]
                    }}
                    transition={{
                        duration: 15,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatType: "mirror",
                        delay: 2
                    }}
                />

                {/* Add subtle grid background */}
                <div
                    className="absolute inset-0 opacity-20"
                    style={{
                        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px), 
                                        linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px)`,
                        backgroundSize: '40px 40px'
                    }}
                />
            </div>

            <div className="max-w-7xl mx-auto px-4 relative z-10">
                {/* Header */}
                <motion.div
                    className="text-center mb-20"
                    initial={{ opacity: 0, y: 30 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.8, ease: [0.25, 0.1, 0.25, 1] }}
                >
                    <span className="text-blue-500 text-sm font-semibold tracking-wider uppercase mb-4 inline-block bg-blue-500/10 px-4 py-1 rounded-full">
                        Featured Product
                    </span>
                    <h2 className="text-5xl md:text-6xl font-bold text-white mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-300 via-white to-purple-300">
                        LeadGen
                    </h2>
                    <p className="text-xl text-gray-300 max-w-3xl mx-auto font-light">
                        Intelligent lead generation platform that enhances your prospecting with AI
                    </p>
                </motion.div>

                {/* Core Capabilities with enhanced design */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
                    {capabilities.map((capability, index) => (
                        <motion.div
                            key={index}
                            className="bg-gradient-to-br from-white/5 to-white/10 backdrop-blur-md rounded-xl p-8 border border-white/10 relative overflow-hidden group"
                            initial={{ opacity: 0, y: 30 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.5, delay: index * 0.15 }}
                            whileHover={{ y: -5, boxShadow: '0 15px 30px -10px rgba(59, 130, 246, 0.3)' }}
                        >
                            {/* Background glow effect */}
                            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 to-purple-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-700" />

                            {/* Animated indicator light */}
                            <div className="absolute top-4 right-4 w-2 h-2 rounded-full bg-blue-400 opacity-70">
                                <motion.div
                                    className="absolute inset-0 rounded-full bg-blue-400"
                                    animate={{
                                        scale: [1, 1.8, 1],
                                        opacity: [0.7, 0, 0.7]
                                    }}
                                    transition={{
                                        duration: 2,
                                        repeat: Infinity,
                                        delay: index * 0.5
                                    }}
                                />
                            </div>

                            <motion.span
                                className="block w-10 h-10 mb-6 rounded-full bg-blue-500/10 flex items-center justify-center text-blue-400"
                                animate={{ rotate: inView ? 0 : -30, scale: inView ? 1 : 0.8 }}
                                transition={{ duration: 0.5, delay: index * 0.15 + 0.2 }}
                            >
                                {index + 1}
                            </motion.span>

                            <h3 className="text-xl font-semibold text-white mb-3 relative">
                                {capability.title}
                                <motion.div
                                    className="absolute -bottom-1 left-0 h-0.5 bg-gradient-to-r from-blue-500 to-purple-500"
                                    initial={{ width: 0 }}
                                    animate={inView ? { width: '40px' } : { width: 0 }}
                                    transition={{ duration: 0.5, delay: index * 0.15 + 0.4 }}
                                />
                            </h3>
                            <p className="text-gray-400">{capability.description}</p>
                        </motion.div>
                    ))}
                </div>

                {/* Features Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-20">
                    {features.map((feature, index) => (
                        <motion.div
                            key={index}
                            className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 relative overflow-hidden"
                            initial={{ opacity: 0, y: 40 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.5, delay: index * 0.1 + 0.4 }}
                            whileHover={{ scale: 1.02, backgroundColor: 'rgba(255, 255, 255, 0.08)' }}
                        >
                            <div className="flex items-start space-x-5">
                                <div className="p-3 bg-blue-500/10 rounded-lg text-blue-400 flex-shrink-0">
                                    {feature.icon}
                                </div>
                                <div>
                                    <h3 className="text-xl font-semibold text-white mb-2">
                                        {feature.title}
                                    </h3>
                                    <p className="text-gray-400">
                                        {feature.description}
                                    </p>
                                </div>
                            </div>

                            {/* Corner accent */}
                            <div className="absolute -top-px -right-px w-20 h-20 overflow-hidden pointer-events-none">
                                <div className="absolute top-0 right-0 w-[140%] h-[140%] -translate-x-1/2 -translate-y-1/2 bg-gradient-to-r from-blue-500/30 to-purple-500/30 blur-sm" />
                            </div>
                        </motion.div>
                    ))}
                </div>

                {/* Enhanced CTA Section */}
                <motion.div
                    className="text-center relative"
                    initial={{ opacity: 0 }}
                    animate={inView ? { opacity: 1 } : {}}
                    transition={{ duration: 0.8, delay: 0.6 }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {/* Glow effect behind button */}
                    <motion.div
                        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-32 h-32 rounded-full bg-blue-500/20 blur-xl"
                        animate={{
                            scale: isHovered ? 1.2 : 1,
                            opacity: isHovered ? 0.3 : 0.15
                        }}
                        transition={{ duration: 0.5 }}
                    />

                    <motion.a
                        href="https://leadgen.neuralway.ai"
                        className="relative inline-flex items-center px-10 py-4 bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-lg font-semibold shadow-lg overflow-hidden group"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        transition={{ type: "spring", stiffness: 400, damping: 15 }}
                    >
                        {/* Shimmer effect */}
                        <motion.div
                            className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent"
                            initial={{ x: '-100%' }}
                            animate={{ x: isHovered ? '200%' : '-100%' }}
                            transition={{ duration: 1, ease: "easeInOut" }}
                        />

                        <span className="relative z-10 flex items-center">
                            Explore LeadGen
                            <svg className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                            </svg>
                        </span>
                    </motion.a>
                </motion.div>
            </div>
        </section>
    );
};

export default LeadGen;