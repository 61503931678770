// src/components/Work.tsx
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Work = () => {
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1
    });

    const controls = useAnimation();
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if(inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    // Array of client logos to showcase
    const clientLogos = [
        {
            name: 'Alpha Technology',
            logo: '/* Placeholder for a logo - in real version, replace with actual logo path */'
        },
        {
            name: 'Beta Innovations',
            logo: '/* Placeholder for a logo - in real version, replace with actual logo path */'
        },
        {
            name: 'Gamma Solutions',
            logo: '/* Placeholder for a logo - in real version, replace with actual logo path */'
        },
        {
            name: 'Delta Enterprises',
            logo: '/* Placeholder for a logo - in real version, replace with actual logo path */'
        },
    ];

    // Create animation for the dots
    const dotVariants = {
        hidden: { opacity: 0, scale: 0 },
        visible: (i: number) => ({
            opacity: 0.6,
            scale: 1,
            transition: {
                delay: i * 0.2,
                duration: 0.5
            }
        })
    };

    return (
        <section id="work" ref={ref} className="py-32 relative overflow-hidden">
            {/* Enhanced Background with Dynamic Effects */}
            <div className="absolute inset-0 pointer-events-none">
                <div className="absolute inset-0 bg-gradient-to-b from-black via-black/95 to-black" />
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,_rgba(56,182,255,0.05)_0%,_transparent_60%)]" />

                {/* Animated particle/star field */}
                <div className="absolute inset-0 overflow-hidden">
                    {[...Array(20)].map((_, i) => (
                        <motion.div
                            key={i}
                            custom={i}
                            variants={dotVariants}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            className="absolute rounded-full bg-white"
                            style={{
                                width: Math.random() * 3 + 1 + 'px',
                                height: Math.random() * 3 + 1 + 'px',
                                left: Math.random() * 100 + '%',
                                top: Math.random() * 100 + '%',
                            }}
                        />
                    ))}
                </div>
            </div>

            <div className="max-w-7xl mx-auto px-4 relative z-10">
                <motion.div
                    ref={ref}
                    className="text-center mb-20"
                    initial={{ opacity: 0, y: 40 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.8, ease: [0.25, 0.1, 0.25, 1] }}
                >
                    <span className="text-blue-400 text-sm font-semibold tracking-wider uppercase mb-4 inline-block bg-blue-500/10 px-4 py-1 rounded-full">
                        Our Projects
                    </span>
                    <h2 className="text-5xl md:text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white via-blue-100 to-white mb-6">
                        Featured Work
                    </h2>
                    <p className="text-white/70 text-lg max-w-2xl mx-auto mb-16 font-light">
                        Discover our latest projects where innovation meets execution
                    </p>

                    {/* Enhanced Portfolio Button */}
                    <Link to="/portfolio">
                        <motion.div
                            className="relative inline-block group"
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            {/* Enhanced 3D Glow Effect */}
                            <motion.div
                                className="absolute -inset-1 bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600 rounded-xl blur-lg"
                                initial={{ opacity: 0.5 }}
                                animate={{
                                    opacity: isHovered ? 0.8 : 0.5,
                                    backgroundPosition: isHovered ? ['0% 0%', '100% 0%'] : '0% 0%'
                                }}
                                transition={{
                                    duration: isHovered ? 3 : 0.3,
                                    ease: "linear",
                                    repeatType: "mirror",
                                    repeat: isHovered ? Infinity : 0
                                }}
                                style={{
                                    backgroundSize: '200% 100%'
                                }}
                            />

                            {/* Button Base with Enhanced 3D Effect */}
                            <div className="relative px-12 py-4 bg-black rounded-xl transform transition-all duration-300"
                                 style={{
                                     boxShadow: `
                                        0 4px 6px -1px rgba(0, 0, 0, 0.5),
                                        0 2px 4px -1px rgba(0, 0, 0, 0.3),
                                        inset 0 1px 2px rgba(255, 255, 255, 0.1)
                                    `
                                 }}
                            >
                                {/* Animated Neon Border with improved effect */}
                                <motion.div
                                    className="absolute inset-0 rounded-xl overflow-hidden opacity-50"
                                    animate={{
                                        backgroundPosition: ['0% 0%', '200% 0%'],
                                        opacity: isHovered ? 0.8 : 0.5
                                    }}
                                    transition={{
                                        duration: 3,
                                        ease: "linear",
                                        repeat: Infinity
                                    }}
                                    style={{
                                        background: `
                                            linear-gradient(90deg, 
                                            #3b82f6, #8b5cf6, #ec4899, #3b82f6)
                                        `,
                                        backgroundSize: '300% 100%',
                                        filter: 'blur(1px)',
                                    }}
                                />

                                {/* Text with enhanced LED effect */}
                                <div className="relative">
                                    {/* Text glow layers for depth */}
                                    <span className="absolute inset-0 text-lg font-medium text-white blur-[2px] opacity-70">
                                        Explore Portfolio
                                    </span>
                                    <span className="absolute inset-0 text-lg font-medium text-white blur-[1px] opacity-80">
                                        Explore Portfolio
                                    </span>
                                    {/* Main text */}
                                    <span className="relative text-lg font-medium text-white" style={{
                                        textShadow: `
                                            0 0 10px rgba(255, 255, 255, 0.7),
                                            0 0 20px rgba(59, 130, 246, 0.5)
                                        `
                                    }}>
                                        Explore Portfolio
                                    </span>
                                </div>

                                {/* Inner glow effect for enhanced depth */}
                                <motion.div
                                    className="absolute inset-0 rounded-xl"
                                    initial={{ opacity: 0 }}
                                    animate={{
                                        opacity: isHovered ? 1 : 0,
                                        background: isHovered ?
                                            'radial-gradient(circle at center, rgba(59, 130, 246, 0.3) 0%, transparent 70%)' :
                                            'none'
                                    }}
                                    transition={{ duration: 0.3 }}
                                />
                            </div>
                        </motion.div>
                    </Link>
                </motion.div>


            </div>
        </section>
    );
};

export default Work;