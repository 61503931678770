// src/App.tsx
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import LeadGen from './components/LeadGen';
import Services from './components/Services';
import Work from './components/Work';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Portfolio from './pages/Portfolio';

// ScrollToTop component to ensure page scrolls to top on route change
const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

// PageTransition component for smooth page transitions
const PageTransition = ({ children }: { children: React.ReactNode }) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            {children}
        </motion.div>
    );
};

// Main Home component
const Home = () => {
    return (
        <PageTransition>
            <div className="bg-black min-h-screen">
                <Navbar />
                <Hero />
                <LeadGen />
                <Services />
                <Work />
                <Contact />
                <Footer />
            </div>
        </PageTransition>
    );
};

function App() {
    return (
        <Router>
            <ScrollToTop />
            <AnimatePresence mode="wait">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                        path="/portfolio"
                        element={
                            <PageTransition>
                                <Portfolio />
                            </PageTransition>
                        }
                    />
                    <Route
                        path="/terms"
                        element={
                            <PageTransition>
                                <Terms />
                            </PageTransition>
                        }
                    />
                    <Route
                        path="/privacy"
                        element={
                            <PageTransition>
                                <Privacy />
                            </PageTransition>
                        }
                    />
                </Routes>
            </AnimatePresence>
        </Router>
    );
}

export default App;